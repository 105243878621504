@import "../../assets/styles/Variables.scss";

.dashboard-page {
  position: relative;

  .page-inner {
    max-width: 750px;
    margin: 0px auto;

    .page-top {
      margin-bottom: 20px;

      ion-grid {
        ion-row {
          align-items: center;
        }
      }

      .leptin-graph {
        text-align: center;

        ion-img {
          max-width: 300px;
        }

        svg {
          width: 350px;
          height: 350px;
        }

        .chart-text {
          fill: #00ff97;
          font: 16px/1.4em "Montserrat", Arial, sans-serif;
          -webkit-transform: translateY(0.5em);
          transform: translateY(0.5em);
        }

        .chart-number {
          font-size: 0.6em;
          line-height: 1;
          text-anchor: middle;
          -webkit-transform: translateY(-0.5em);
          transform: translateY(-0.5em);
          font-family: revert;
        }

        .chart-subtext {
          font-size: 0.1rem;
          line-height: 1;
          text-anchor: middle;
        }

        @media only screen and (max-width: 767px) {
          svg {
            width: 250px;
            height: 250px;
          }
        }
      }

      .leptinLabels {
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0px;
        justify-content: center;

        li {
          list-style: none;

          span {
            margin-left: 10px;
            font-size: 14px;
            line-height: 18px;
            display: inline-block;

            &.white {
              color: #fff;
            }

            &.red {
              color: #ea1604;
            }

            &.blue {
              color: #0095ff;
            }

            &.green {
              color: #00ff97;
            }
          }
        }
      }
    }
  }

  .custom-drop {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 2;
  }
}

.toast-custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  p {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    ion-icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
