@import "../../assets/styles/Variables.scss";

.meals-page {
  .page-inner {
    .common-tab {
      .tab-contant {
        max-width: 750px;
        margin: 0px auto;
      }
    }

    //progress-image-list
    .progress-image-list {
      display: flex;
      flex-wrap: nowrap;
      .img-item {
        margin: 10px;
        ion-img {
          width: 75px;
          height: 75px;
          display: inline-block;
          object-fit: cover;
          transition: transform 0.1s linear, -webkit-transform 0.1s linear;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
//drop-btn-right
.drop-btn-right {
  text-align: right;
}
