@import "../../assets/styles/Variables.scss";

.common-modal {
  --max-height: auto;
  --height: auto;
  --width: 90%;
  --border-radius: 10px;
  &.change-password {
    --max-height: 350px;
    .change-height {
      max-height: 350px;
      overflow: auto;
    }
  }
  &.show-alert {
    --max-height: 400px;
    .alert-svg {
      width: 45px;
      height: 45px;
      margin-right: 15px;
    }
  }
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $tertiary;
    margin-bottom: 20px;
    h5 {
      margin: 0;
    }
  }

  .success-modal {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    padding: 20px;
    text-align: center;
    .check-green {
      color: $primary;
      font-size: 60px;
    }
    .alert-red {
      color: $danger;
      font-size: 60px;
    }
  }

  // ion-datetime {
  //   color: $black;
  // }
}

.modal-radio-bg {
  ion-item {
    --background: transparent;
  }
}
