@import "../assets/styles/Variables.scss";

ion-menu {
  .menu-top {
    padding: 40px;
    background: #8d6e63;
    text-align: center;
    h2 {
      color: $white;
      font-size: 24px;
      line-height: 28px;
      margin: 0px;
      opacity: 0.9;
    }
    span {
      color: #ff9100;
    }
  }
  ion-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    ion-menu-toggle {
      ion-item {
        --background: #{$baseColor};
        --border-width: 0px;
        --color: #{$white};
        border-bottom: 1px solid #454545;
        // --background-hover: #{$dark};
        // --background-hover-opacity:1;
        cursor: pointer;
        &:hover {
          --background: #252525;
        }
      }
    }
  }
}
