@import "../../assets/styles/Variables.scss";

.unauthorize-page {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 100px);
  .page-inner {
    width: 100%;
    text-align: center;
    .unauthorize-box {
      h3 {
        font-size: 60px;
        line-height: 80px;
        font-weight: bolder;
        color: $primary;
        margin: 0px 0px 10px;
      }
      h1 {
        text-transform: capitalize;
        color: $white;
        font-size: 24px;
        line-height: 30px;
        margin: 0px;
      }
    }
  }
}
